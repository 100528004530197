import PropTypes from 'prop-types'
import React from 'react';
import { Layout } from 'antd';
import '../stylesheets/Header.css';

const { Header } = Layout;

const InnoHeader = ({ stripped, headerTitle, beta, devTag, user, logOut }) => {

    return <React.Fragment>
        {(!stripped) ?
            <Header id="header">
                <div id={"header-content"}>
                    
                    <div id={"header-logo-text"}>
                        <div id="logo"/>
                        <div id="header-txt">
                            {headerTitle} {devTag === 'STAGE' || devTag === 'DEV' ? `- ${devTag} ENVIRONMENT` : ``}
                        </div>
                        {beta ? <div id="beta">BETA</div> : <div></div>}
                    </div>
                    <div id={"header-user"} 
                        onClick={logOut} 
                        onMouseEnter={(e) => e.target.innerHTML = `${user} - sign out`}
                        onMouseLeave={(e) => e.target.innerHTML = user }>
                        { user === '' ? `` : user}
                    </div>
                </div>
            </Header>
            : null}
    </React.Fragment>
}

export default InnoHeader;

InnoHeader.propTypes = {
    stripped: PropTypes.bool
}