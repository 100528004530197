import React, { useEffect } from 'react';
import { PageHeader, Typography, Divider } from 'antd';
import 'antd/dist/antd.min.css';
import '../stylesheets/InnoTapForm.css';
import { Buffer } from 'buffer';

let loginPageCallback;

const { Paragraph } = Typography;

const Login = ({ loggedInDisplay }) => {
    loginPageCallback = loggedInDisplay;

    window.onSuccess = (response) => {
        //console.log('Hello ' + Object.keys(response), "!");
        sessionStorage.setItem('credential', response.credential);
        const cred = JSON.parse(Buffer.from(response.credential.split('.')[1], 'base64').toString());
        sessionStorage.setItem('user', cred.email);
        sessionStorage.setItem('expires', cred.exp);
        loginPageCallback();
    };

    useEffect(() => {
        /*
        <script src="https://accounts.google.com/gsi/client" async></script>
        */
        const script = document.createElement('script');
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        document.body.appendChild(script);

        return (() => {
            document.body.removeChild(script);
        });

    }, []);

    return (
        <React.Fragment>
            {CustomPageHeader}
            <Divider/>                              
            <div id="g_id_onload"
                 data-client_id={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
                 data-context="signin"
                 data-ux_mode="popup"
                 data-callback="onSuccess"
                 data-auto_prompt="false">
            </div>
            <div className="g_id_signin"
                 data-type="standard"
                 data-shape="rectangular"
                 data-theme="outline"
                 data-text="signin_with"
                 data-size="large"
                 data-logo_alignment="left">
            </div>
        </React.Fragment>
    );
};

const title = (<span>The Right One</span>);
const CustomPageHeader = (
    <PageHeader title={title} style={{"textAlign": "left", "padding": 0}}>
        <div className="wrap">
            <div className="content">
                <Paragraph/>Please login using your Google account associated with Innovid
            </div>
        </div>
    </PageHeader>
);

export default Login;