import React, { useState, useRef, useEffect } from 'react';
import { Button, Divider, Form, Input, PageHeader, Row, Typography, Tag, Tooltip, notification } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import 'antd/dist/antd.variable.min.css';
import '../stylesheets/InnoTapForm.css';
import axios from 'axios';

const FIELD_ERROR_MESSAGE_AUTHENTICATION = 'Please verify you have the proper credentials.';
const FIELD_ERROR_MESSAGE_USER_LOCKED = 'This TTD user is locked. The password must be reset to unlock the user.';

const form = React.createRef();
const buttonClear = React.createRef();
const buttonValidate = React.createRef();
const buttonSubmit = React.createRef();

const { Paragraph } = Typography;
const layout = { labelCol: { span: 9 }, wrapperCol: { span: 20 }, size: 'large', align: 'left' };

const InnoTapForm = ({ devTag, showAPIResponse}) => {
    
    const [partnerCredentials, setPartnerCredentials] = useState({
        id: null,
        username: null,
        password: null,
    });
    const [isValidating, setIsValidating] = useState(false);
    const [hasPassedValidation, setHasPassedValidation] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    
    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);
    
    const onPressEnterPartnerCreds = (e) => {
        e.preventDefault();
    };
    
    const handleClose = (removedTag) => {
        console.log(`handleClose('${removedTag}')`);
        const newTags = tags.filter(tag => tag !== removedTag);
        setTags(newTags);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        console.log("handleInputConfirm");
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue]);
        }
        setInputVisible(false);
        setInputValue('');
    };

    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;
        setTags(newTags);
        setEditInputIndex(-1);
        setInputValue('');
    };
    
    // Clear Form
    const onClickButtonClear = values => {
        //console.log(`onClickButtonClear(${Object.keys(values)})`);
        setHasPassedValidation(false);
        setTags([]);
        form.current.resetFields();
    };
    
    // Validate in the necessary order
    const onClickButtonValidate = async(values) => {
        //console.log(`onClickButtonValidate(${Object.keys(values)})`);
        setIsValidating(true);

        axios.defaults.baseURL = 'https://apisb.thetradedesk.com/v3';
        
        // validate Partner login and password
        //const url = `${process.env.REACT_APP_TTD_URL}/authentication`;
        let url = `/authentication`;
        await axios.post(url, {login: partnerCredentials.username, password: partnerCredentials.password })
        .then((response) => {
            //console.log(response);
            //setPartnerCredentials({token: response.data.Token, ...partnerCredentials });
            sessionStorage.setItem('TTD-Auth', response.data.Token);
        }, (error) => {
            console.log(error.config);
            form.current.setFields([{
                name:"ttdPartnerLogin",
                validateStatus:"error",
                errors: [error.response.data === 'User locked' ? null : FIELD_ERROR_MESSAGE_AUTHENTICATION]
            },{
                name:"ttdPartnerPassword",
                validateStatus:"error",
                errors: [error.response.data === 'User locked' ? FIELD_ERROR_MESSAGE_USER_LOCKED : FIELD_ERROR_MESSAGE_AUTHENTICATION],
            }]);
            setHasPassedValidation(false);
            setIsValidating(false);
            return;
        });            
            
        // set Partner ID
        url = `/partner/query`;
        await axios.post(url, {
                "PageStartIndex": 0,
                "PageSize": null
            },
            {
                headers: {
                    'content-type': 'application/json',
                    'TTD-Auth': sessionStorage.getItem('TTD-Auth')
                },
            }
        )
        .then((response) => {
            //console.log(response);
            const partnerId = response.data.Result[0].PartnerId;
            const partnerName = response.data.Result[0].PartnerName;
            sessionStorage.setItem('PartnerId', partnerId);
            form.current.setFields([{
                name:"ttdPartnerId",
                value: partnerId
            },{
                name:"ttdPartnerName",
                value: partnerName
            }]);
        }, (error) => {
            console.log(error.config);
            form.current.setFields([{
                name:"ttdPartnerId",
                validateStatus:"error",
                errors: error.response.data
            }]);
            setHasPassedValidation(false);
            setIsValidating(false);
            return;
        });

        // validate Advertiser IDs
        url = `/advertiser/query/partner`;
        await axios.post(url, {
                "PartnerId": sessionStorage.getItem('PartnerId'),
                "PageStartIndex": 0,
                "PageSize": null
            },
            {
                headers: {
                    'content-type': 'application/json',
                    'TTD-Auth': sessionStorage.getItem('TTD-Auth')
                },
            }
        )
        .then((response) => {
            //console.log(response);
            response.data.Result.forEach((val) => {tags.push(val.AdvertiserId)});
        }, (error) => {
            console.log(error.config);
            setHasPassedValidation(false);
            setIsValidating(false);
            return;
        });

        // validation passed
        setHasPassedValidation(true);
        setIsValidating(false);
        return;

    };    
    // Handle on form submission
    const onFormSubmit = async(values) => {
        //console.log(`onFormSubmit(${Object.keys(values)})`);
        setIsSubmitting(true);
        values.tags = tags;
        axios.defaults.baseURL = "";
        const url = `https://yis4yb7jyi.execute-api.us-east-1.amazonaws.com/prod`;
        await axios.post(url, values,
            {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': sessionStorage.getItem('credential')
                },
            }
        )
        .then((response) => {
            console.log(response);
            notification.open({
                message: response.data.name,
                description: response.data.message,
                duration: 0,
            });
            if (response.data.statusCode === 200) {
                setTags([]);
                form.current.resetFields();
                setHasPassedValidation(false);
            }
        }, (error) => {
            console.log(JSON.stringify(error));
            if (error instanceof Error) {
                notification.open({
                    message: 'Error',
                    description: error.message,
                    duration: 0,
                });
            }
            return;
        });
        setIsSubmitting(false);
        return;
    };
    
    // Handle Form Failed
    const onFinishFailed = error => {
        console.log('Failed:', error);
        notification.open({
            message: 'Submission Failed',
            description: `${error.errorFields.length} field(s) failed validation.`,
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    const onChangePartnerCredentials = (e) => {
        //console.log("onChangePartnerCredentials", e.target.name, e.target.value);
        setPartnerCredentials({
            ...partnerCredentials,
            [e.target.id]: e.target.value,
        });
        
        // When authentication fails we can't tell whether the id or password (or both)
        // is the problem based on the TTD response. This code removes the error message(s)
        // b/c when either id or password change the other field's value might be valid
        for (var itemName of ['id','password']) {
            var errorMessages = form.current.getFieldError(itemName);
            for (var i = 0; i < errorMessages.length; i++) {
                var errorMessage = errorMessages[i];
                if (errorMessage === FIELD_ERROR_MESSAGE_AUTHENTICATION) {
                    errorMessages.splice(i--, 1);
                }
            }
        }
        
    };
    
    return (
        <React.Fragment>
            { CustomPageHeader }

            <Divider/>

            <Form {...layout} ref={form} name='innoTapForm' initialValues={{emailSubmitter: sessionStorage.getItem('user')}} onFinish={onFormSubmit} onFinishFailed={onFinishFailed}>
                
                <Form.Item label="TTD Partner Login" name='ttdPartnerLogin' rules={[{ required: true, type: 'email', message: 'Valid email required'}]}>
                    <Input
                        id='username'
                        name='Partner Login'
                        disabled={hasPassedValidation}
                        onChange={onChangePartnerCredentials}
                        placeholder="ttd_api_lorem@ipsum.com"
                        required
                        type='text'
                        value={partnerCredentials.username}
                        onPressEnter={onPressEnterPartnerCreds}
                    />
                </Form.Item>

                <Form.Item label='TTD Partner Password' name='ttdPartnerPassword' rules={[{ required: true, message: 'Password required'}]}>
                    <Input.Password
                        id='password'
                        size='middle'
                        disabled={hasPassedValidation}
                        onChange={onChangePartnerCredentials}
                        placeholder="xxxxxxxx"
                        value={partnerCredentials.password}
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        onPressEnter={onPressEnterPartnerCreds}
                    />
                </Form.Item>
                
                <Row justify="end">
                    <Form.Item>
                        <Button 
                            ref={buttonValidate}
                            type="primary"
                            disabled={isValidating | hasPassedValidation}
                            onClick={onClickButtonValidate}
                        >Validate
                        </Button>
                    </Form.Item>
                </Row>
                <Divider />
                <Form.Item label='TTD Partner ID' name='ttdPartnerId'
                    rules={[
                        { type: 'string', min: 7, message: "Must be at least 7 characters" }
                    ]}>
                    <Input
                        id='id'
                        bordered={false} 
                        disabled
                        onChange={onChangePartnerCredentials}
                        type='text'
                        value={partnerCredentials.id}
                    />
                </Form.Item>
                <Form.Item label='TTD Partner Name' name='ttdPartnerName'>
                    <Input
                        bordered={false} 
                        disabled
                        onChange={onChangePartnerCredentials}
                        type='text'
                        value={partnerCredentials.name}
                    />
                </Form.Item> 
                <Form.Item label="TTD Advertiser ID" 
                    rules={[
                        () => ({
                            validator(_, value) {
                                if (/^[A-Za-z0-9]+$/.test(value) | value === '') {
                                    return Promise.resolve();
                                } else if  (/\s/.test(value)) {
                                    return Promise.reject(new Error('Cannot contain spaces'));
                                }
                                return Promise.reject(new Error('Must be alphanumeric'));
                            },
                        }),
                        { type: 'string', min: 7, message: "Must be at least 7 characters" },
                    ]}>
                

                    {tags.map((tag, index) => {
                        if (editInputIndex === index) {
                          return (
                            <Input
                              ref={editInputRef}
                              key={tag}
                              size="small"
                              className="tag-input"
                              value={editInputValue}
                              onChange={handleEditInputChange}
                              onBlur={handleEditInputConfirm}
                              onPressEnter={handleEditInputConfirm}
                            />
                          );
                        }
                        const isLongTag = tag.length > 20;
                        const tagElem = (
                            
                            <Tag
                                className="edit-tag"
                                key={tag}
                                closable
                                onClose={() => handleClose(tag)}
                            >
                                <span
                                    onDoubleClick={e => {
                                        if (!hasPassedValidation) {
                                            setEditInputIndex(index);
                                            setEditInputValue(tag);
                                            e.preventDefault();
                                        }
                                    }}
                                >
                                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                </span>
                            </Tag>
                        );
                    return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                            {tagElem}
                        </Tooltip>
                    ) : (
                      tagElem
                    );
                    })}
                    {inputVisible && (
                        <Input
                            ref={inputRef}
                            type="text"
                            size="small"
                            className="tag-input"
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={handleInputConfirm}
                            onPressEnter={handleInputConfirm}
                        />
                    )}
                </Form.Item>
                <Divider />
                <Form.Item label="Credential Owner Email" name="emailOwner" rules={[{ required: true, type: 'email', message: 'Valid email required'}]} >
                    <Input placeholder="lorem@ipsum.com"/>
                </Form.Item>
                <Form.Item label="Your Email" name="emailSubmitter">
                    <Input disabled/>
                </Form.Item>
                <Divider />

                <Row justify="end">
                    <Form.Item>
                        <Button 
                            ref={buttonClear}
                            htmlType="reset"
                            disabled={isSubmitting | isValidating}
                            onClick={onClickButtonClear}
                        >Clear
                        </Button>
                    </Form.Item>
                    &nbsp;&nbsp;&nbsp;
                    <Form.Item>
                        <Button 
                            ref={buttonSubmit}
                            type="primary"
                            disabled={isSubmitting | !hasPassedValidation}
                            htmlType="submit"
                        >Submit
                        </Button>
                    </Form.Item>
                </Row>

            </Form>     
        </React.Fragment>
    );
};

const title = (<span>Update Partner Credentials</span>);
const CustomPageHeader = (
    <PageHeader title={title} style={{"textAlign": "left", "padding": 0}}>
        <div className="wrap">
            <div className="content">
                <Paragraph/>Securely store new TTD Partner credentials or update existing credentials
            </div>
        </div>
    </PageHeader>
);

export default InnoTapForm;

