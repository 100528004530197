import React from 'react'
import { Button, PageHeader, Divider, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons'
import 'antd/dist/antd.min.css';
import '../stylesheets/InnoTapForm.css';

const APIResponse = ({ showAPIResponse, apiMessage }) => {

    const closeMessage = () => {
        showAPIResponse(false, '')
    }

    return (
        <React.Fragment>
                        
            <Row justify="end">
                <Button type="primary" onClick={closeMessage}><CloseOutlined />Close</Button>
            </Row>

            {CustomPageHeader}
            <Divider/>
            
            { apiMessage }
            
        </React.Fragment>
    )

};

const title = (<span>Tag Uploader</span>);
const CustomPageHeader = (
    <PageHeader title={title} style={{"textAlign": "left", "padding": 0}}></PageHeader>
)

export default APIResponse;
