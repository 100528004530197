import { useState, useEffect } from 'react';
import axios from "axios/index";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Content from './Content.js';
import * as isLocalHost from '../isLocalHost';

import "../stylesheets/App.css";
import '../stylesheets/index.less';

const App = () => {

    const isAlreadyLoggedIn = () => {
        let dateNow = new Date();
        let secondsExpired = sessionStorage.getItem('expires');
        let dateExpired = secondsExpired ? new Date(Number(sessionStorage.getItem('expires')*1000)) : null;
        if (!secondsExpired) {
            console.log("Not logged-in, no expiration date");
            return false;
        } else if (dateNow > dateExpired) {
            console.log("Not logged-in, expired");
            return false;
        }
        //console.log("Already logged-in");
        return true;
    };

    const devTag = process.env.DEV_STAGE_PROD;
    const [stripped, setStripped] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isPageAvailable, setIsPageAvailable] = useState(true);
    const [headerTitle, setHeaderTitle] = useState('');
    const [version, setVersion] = useState('');
    const [beta, setBeta] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(isAlreadyLoggedIn());
    const [responseMessageOn, setResponseMessageOn] = useState(false);
    const [apiMessage, setApiMessage] = useState('');
    const [user, setUserData] = useState(sessionStorage.getItem('user'));

    // Load Config 
    useEffect(() => {
        const public_url = isLocalHost.setRoot() ? 'html/public' : ''; 
        axios(`${public_url}/config.json`)
            .then((data) => {
                setIsLoading(false);
                setIsPageAvailable(data.data.isPageActive);
                setHeaderTitle(data.data.headerTitle || '');
                setVersion(data.data.version || '');
                setBeta(data.data.beta);
                document.title = data.data.headerTitle || '';                
            });
    }, []);

    useEffect(() => {
        setStripped(resolveStripped);
    }, []);

    const loggedInDisplay = async () => {
        //console.log("loggedInDisplay");
        setUserData(user ? user : sessionStorage.getItem("user"));
        setIsLoggedIn(true);
    };

    const logOut = () => {
        console.log("logout()");
        // Clear login session
        sessionStorage.removeItem('user'); 
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('expires');
        setIsLoggedIn(false);
        setUserData('');
    };

    // Show Message Panel
    const showAPIResponse = async(state, msg) => {
        console.log(`showAPIResponse(${state},${msg})`);
        setResponseMessageOn(state);
        setApiMessage(msg);        
    };

    return (
        <div className="App">
            <Header stripped={stripped} headerTitle={headerTitle} beta={beta} devTag={devTag} user={user} logOut={logOut}/>
            <Content 
                devTag={devTag}
                isLoading={isLoading} 
                isPageAvailable={isPageAvailable} 
                isLoggedIn={isLoggedIn} 
                loggedInDisplay={loggedInDisplay}
                responseMessageOn={responseMessageOn} 
                showAPIResponse={showAPIResponse}
                apiMessage={apiMessage}
            />
            <Footer stripped={stripped} version={version}/>
        </div>
    );
};

const resolveStripped = () => {
    //console.log("resolveStripped()", window.location.href);
    let stripped = false;
    if (window.location.href.split('?')[1]) {
        window.location.href.split('?')[1].split('&').forEach((query, i) => {
            if (query.split('=')[0] === 'stripped' && query.split('=')[1] === 'true') {
                stripped = true;
            }
        });
    }
    return stripped;
};

export default App;
