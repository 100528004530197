import React from 'react';
import { Row, Col, Layout } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import PageUnavailable from './PageUnavailable';
import InnoTapForm from './Form';
import Login from './Login';
import APIResponse from './APIResponse';
import 'antd/dist/antd.min.css';
import '../stylesheets/Content.css';

const { Content } = Layout;

const InnoContent = ({devTag, isLoading, isPageAvailable, isLoggedIn, loggedInDisplay, responseMessageOn, showAPIResponse, apiMessage}) => {

    return (
        <Content className='content-wrapper'>
            <Row type="flex" justify="center">
            <Col xs={16} sm={20} md={20} lg={18} xl={15}>
                    <div className="main-content main-content-box">
                        {isLoading ? ContentLoader
                            : <React.Fragment>
                                { !isPageAvailable ?
                                    <PageUnavailable/> :
                                        <React.Fragment>
                                            { isLoggedIn ?                                             
                                                !responseMessageOn ? <InnoTapForm devTag={devTag} showAPIResponse={showAPIResponse} /> 
                                                    : <APIResponse showAPIResponse={showAPIResponse} apiMessage={apiMessage} />
                                                        : <Login loggedInDisplay={loggedInDisplay} /> 
                                            }
                                        </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </div>
                </Col>
            </Row>
        </Content>
    );
};

const ContentLoader = (
    <Row>
        <div className="contentLoader">Loading</div>
        <div className="icons-list"><SyncOutlined spin /></div>
    </Row>
);

export default InnoContent;