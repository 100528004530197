
import * as React from 'react';

const PageUnavailable = () => {
    return (
        <div>
            No Content
        </div>
    );
}

export default PageUnavailable;